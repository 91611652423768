<template>
    <span class="request-status">
        <span :class="status.toLowerCase()">
            {{parseStatus(status)}}
        </span>
    </span>
</template>

<script>
    export default {
        name: "RequestStatus",
        props: ['status'],
        methods: {
            parseStatus(status){
                switch(status){
                    case 'PENDING':
                        return 'на модерации';
                    case 'APPROVED':
                        return 'Принято';
                    case 'WAIT_PAYMENT':
                        return 'ожидает оплаты';
                    default:
                        return 'отклонен';
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .request-status{
        font-size: 12px;
        color: #fff;
        text-transform: uppercase;
        white-space: nowrap;
        span{
            padding: 4px 8px;
            border-radius: 2px;
            background: #dc3545;
            &.pending{
                background: #e4c03f;
            }
            &.wait_payment{
                background: #8ea515;
            }
            &.approved{
                background: #5cb82c;
            }
        }
    }
</style>